module.exports = {
  API_LOCATION: '/api',
  DEFAULT_AGGREGATION_FIELDS: [
    'meta.depicts', 'meta.genre', 'meta.location',
    'meta.medium', 'meta.object_type', 'meta.institution',
    'meta.artist_name', 'origin.name',
  ],
  EXCLUDE_ANNOTATION_NAMES: [
    'very small', 'small', 'medium', 'large', 'very large',
  ],
  PLUGIN_ICONS: {
    iconclass_clip_classifier: 'mdi-alpha-c-circle-outline',
    iconclass_lstm_classifier: 'mdi-alpha-l-circle-outline',
    kaggle_resnet_classifier: 'mdi-alpha-k-circle-outline',
    i_met2020_resnet_classifier: 'mdi-alpha-m-circle-outline',
    image_net_resnet_classifier: 'mdi-alpha-i-circle-outline',
  },
  URL_PATH_PREFIX: '',
  VUE_MATOMO_URL: 'https://support.tib.eu/piwik/',
  VUE_MATOMO_SITE_ID: 47,
};
